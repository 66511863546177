<template>
  <div class="jc_bottom_box">
    <div class="jc_bottom_ul">
      <div class="jc_bottom_li">
        <div class="jc_li_title">关于精宸</div>
        <div class="jc_li" @click="toRouter('/about')">公司介绍</div>
        <div class="jc_li" @click="toRouter('/about')">企业文化</div>
        <div class="jc_li" @click="toRouter('/about')">发展历程</div>
        <div class="jc_li" @click="toRouter('/about')">荣誉资质</div>
      </div>
      <div class="jc_bottom_li">
        <div class="jc_li_title">解决方案</div>
        <div class="jc_lis" style="display: flex">
          <div style="margin-right: 0.25rem" @click="toRouter('/Programme')">
            互联网医院
          </div>
          <div @click="toRouter('/Programme')">医务管理</div>
        </div>
        <div class="jc_li" @click="toRouter('/Programme')">号源平台</div>
        <div class="jc_li" @click="toRouter('/Programme')">一站式结算</div>
        <div class="jc_li" @click="toRouter('/Programme')">一卡通</div>
      </div>
      <div class="jc_bottom_li">
        <div class="jc_li_title">应用案例</div>
        <div class="jc_li">客户案例</div>
      </div>
      <div class="jc_bottom_li">
        <div class="jc_li_title">咨讯中心</div>
        <div class="jc_li">公司动态</div>
        <div class="jc_li">联系我们</div>
      </div>
      <div class="jc_bottom_li">
        <div class="jc_li_title">全国客服电话</div>
        <div class="jc_li">400-820-6083</div>
        <img
          style="width: 0.76rem; height: 0.76rem"
          src="../assets/images/qrcode.png"
          alt=""
        />
      </div>
    </div>
    <div class="jc_bottom_text">
      版权所有 © 上海精宸科技集团股份有限公司 2021
      <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer"
        >沪ICP备14005130号</a
      >
      保留版权所有 技术支持：精宸科技
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      path: "",
    };
  },
  methods: {
    toRouter(url) {
      if (this.$route.path == url) {
        return;
      }
      document.documentElement.scrollTop = 0;
      this.$router.push(url);
    },
  },
};
</script>
<style scoped lang="less">
.jc_bottom_box {
  width: 100%;
  background: #eeeeee;
  padding: 0.6rem;
  .jc_bottom_ul {
    display: flex;
    padding: 0 3.8rem;
    .jc_bottom_li {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .jc_li_title {
        font-size: 0.16rem;
        font-family: OPPOSans;
        font-weight: bold;
        color: #3e3a39;
        text-align: center;
      }
      .jc_li:hover,
      .jc_lis > div:hover {
        color: #1941b1;
        text-decoration: underline;
        cursor: pointer;
      }
      .jc_li,
      .jc_lis {
        font-size: 0.12rem;
        font-family: OPPOSans;
        font-weight: 300;
        color: #3e3a39;
        margin-top: 0.2rem;
        text-align: center;
      }
    }
  }
  .jc_bottom_text {
    padding: 0.34rem 0 0rem;
    text-align: center;
    font-size: 0.14rem;
    font-family: OPPOSans;
    font-weight: 400;
    color: #3e3a39;
    border-top: 0.01rem solid #b8b8b8;
    margin-top: 0.21rem;
  }
  a {
    color: #3e3a39;
    text-decoration: none;
  }
}
</style>