<template>
  <div class="programme_box">
    <div class="banner">
      <img src="../assets/images/programme/banner.png" alt="" />
      <div class="text">
        <span>智慧服务评级</span>
        <div>
          通过一站式智慧一样解决方案，为医院全方位建设信
          息化就医流程，打造多场景线上服务模式，全方位提 高患者就诊体验。
        </div>
      </div>
    </div>
    <div class="navbar_box">
      <el-tabs
        :value="activeName"
        @tab-click="handleClick"
        style="font-size: 0.2rem"
      >
        <el-tab-pane
          :label="item"
          :name="String(index)"
          v-for="(item, index) in navlist"
          :key="index"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div class="online_box" v-if="activeName == 0">
      <div class="o_title">
        <span>互联网医院</span>
        <div></div>
        <p>预约挂号 / 在线问诊 / 线上缴费 / 处方流转 / 报告查询 / 药品配送</p>
        <span
          >实现全院信息化就医流程建设，线上线下双通道，打造更优质的就医体验</span
        >
      </div>
      <div class="content_text">
        近年来，医疗服务领域各类新形态不断涌现，“互联网+医疗”作为国家主推的新型医疗模式，在挂号结算、远程诊疗、
        咨询服务等方面提供了全新的思路。精宸科技自主研发的互联网医院平台，以优化患者就医流程为切入点，充分应用各种成
        熟技术（微信、小程序、移动支付等），通过信息平台实现对就医流程的全面线上化，着力解决诸如门诊“三长一短”等现象，
        全面提升患者服务质量，满足不断人们提高的健康需求，并通过平台为医院实现智慧服务评级的第三级要求。
      </div>
      <img src="../assets/images/programme/online/content1.png" alt="" />
      <div class="content_text">
        精宸互联网医院解决方案，包含了两平台（互联网医院管理平台、处方流转平台）、三应用（医生端、患者端、药店
        端），打造覆盖医院全方位服务的新型就医空间。基于微信小程序技术，为患者提供手机端的便捷就诊服务。互联网医院
        通过在线问诊、处方流转、药品配送等功能构建全流程的线上医院服务，同时具备挂号、缴费、报告查询、病案预约等多
        种功能，为医院提供线上支持，简化医疗服务流程，优化服务效率，减少患者就医时间，提高满意度。
      </div>
      <img src="../assets/images/programme/online/content2.png" alt="" />
    </div>
    <div class="number_box" v-if="activeName == 1">
      <div class="o_title">
        <span>号源平台</span>
        <div></div>
        <p>分时号源 / 多渠道预约 / 统一接口 / 多业务预约</p>
        <span
          >号源管理平台支持各渠道的预约问诊，具备易操作的业务管理功能及可靠的安全管理机制</span
        >
      </div>
      <img src="../assets/images/programme/number/content.png" alt="" />
    </div>
    <div class="settlement_box" v-if="activeName == 2">
      <div class="o_title">
        <span>一站式结算平台</span>
        <div></div>
        <p>统一支付平台</p>
        <span
          >聚合全院支付渠道，整合全院支付场景，实现一站式移动支付管理模式</span
        >
      </div>
      <img
        class="img1"
        src="../assets/images/programme/settlement/content.png"
        alt=""
      />
      <div class="o_title">
        <p>统一对账平台</p>
        <span>多方多渠道收入统一管理、统一对账</span>
      </div>
      <img
        class="img2"
        src="../assets/images/programme/settlement/content1.png"
        alt=""
      />
      <div class="o_title">
        <p>电子票据管理平台</p>
        <span
          >电子票据实时在线、实时交付，支持自主开票，将开票系统和业务系统对接，对各类发票进行统一管理</span
        >
      </div>
      <img
        class="img3"
        src="../assets/images/programme/settlement/content2.png"
        alt=""
      />
      <div class="o_title">
        <p>统一监管平台</p>
        <span>统一监管平台，构建全院资金管理体系，实现全局统筹及安全监管</span>
      </div>
      <img
        class="img3"
        src="../assets/images/programme/settlement/content3.png"
        alt=""
      />
    </div>
    <div class="oneCard_box" v-if="activeName == 3">
      <div class="o_title">
        <span>一站式结算平台</span>
        <div></div>
        <p>统一支付平台</p>
        <span
          >聚合全院支付渠道，整合全院支付场景，实现一站式移动支付管理模式</span
        >
      </div>
      <img
        class="img1"
        src="../assets/images/programme/onecard/content.png"
        alt=""
      />
    </div>
    <div class="medical_box" v-if="activeName == 4">
      <div class="o_title">
        <span>医务管理平台</span>
        <div></div>
        <p>医疗质量管理 / 医疗准入管理 / 医师档案管理 / 综合管理</p>
        <span
          >全院信息实时展示，工作流程统一管理，高效分工，管理效率大大提高</span
        >
      </div>
      <img
        class="img1"
        src="../assets/images/programme/medical/content.png"
        alt=""
      />
      <div class="o_title">
        <p>业务架构</p>
      </div>
      <img
        class="img2"
        src="../assets/images/programme/medical/content1.png"
        alt=""
      />
      <div class="o_title">
        <p>建设目标</p>
      </div>
      <img
        class="img3"
        src="../assets/images/programme/medical/content2.png"
        alt=""
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "Programme",
  data() {
    return {
      activeName: 0,
      navlist: [
        "互联网医院",
        "号源平台",
        "一站式结算平台",
        "一卡通",
        "医务管理平台",
      ],
    };
  },
  methods: {
    handleClick(tab, event) {
      // switch (tab.name) {
      //   case "1":
      //     if (this.activeName != 1) {
      //       this.$router.push({
      //         name: "Index",
      //       });
      //     }

      //     break;
      //   case "2":
      //     if (this.activeName != 2) {
      //       this.$router.push({
      //         name: "About",
      //       });
      //     }

      //     break;
      //   case "3":
      //     if (this.activeName != 3) {
      //       this.$router.push({
      //         name: "Programme",
      //       });
      //     }
      //     break;
      //   default:
      //     break;
      // }
      this.activeName = tab.name;
    },
  },
};
</script>
<style scoped lang="less">
.programme_box {
  .banner {
    width: 100%;
    height: 5.72rem;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .text {
      position: absolute;
      left: 3.41rem;
      top: 1.26rem;
      span {
        font-size: 0.8rem;
        font-family: OPPOSans;
        font-weight: 800;
        color: #ffffff;
      }

      div {
        font-size: 0.2rem;
        font-family: OPPOSans;
        font-weight: 400;
        color: #ffffff;
        margin-top: 0.3rem;
        width: 4.8rem;
        text-align: justify;
      }
    }
  }
  .navbar_box {
    overflow: hidden;
    width: 12.44rem;
    height: 1.24rem;
    background: #ffffff;
    box-shadow: 0px 0.11rem 0.13rem 0px rgba(205, 220, 236, 0.29);
    border-radius: 0.1rem;
    margin: -1.24rem auto 0;
    position: relative;
    /deep/ .el-tabs,
    /deep/ .el-tabs__header,
    /deep/ .el-tabs__nav-wrap,
    /deep/ .el-tabs__nav-scroll {
      height: 100%;
    }
    /deep/ .el-tabs__nav {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-evenly;
    }
    /deep/ .el-tabs__header {
      margin: 0;
    }
    /deep/ .el-tabs__nav-wrap::after {
      display: none;
    }
    /deep/ .el-tabs__item {
      width: 20%;
      font-weight: bold;
      padding: 0 0.2rem;
      height: 100%;
      box-sizing: border-box;
      line-height: 1.24rem;
      font-size: 0.28rem;
      color: #363636;
      text-align: center;
    }
    /deep/ .el-tabs__item:hover {
      color: #1941b1;
    }
    /deep/ .el-tabs__item.is-active {
      color: #1941b1;
    }
    /deep/ .el-tabs__active-bar {
      background-color: #1941b1;
      height: 0.02rem;
    }
  }
  .online_box {
    width: 12.44rem;
    margin: 0 auto;
    margin-top: 0.65rem;
    img {
      height: 5.36rem;
      margin: 0.9rem 0 0.8rem;
    }
  }
  .o_title {
    width: 100%;
    font-size: 0.48rem;
    font-family: OPPOSans;
    font-weight: 800;
    color: #231815;
    line-height: 0.68rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 0.01rem solid #dbdada;
    padding-bottom: 0.62rem;
    margin-bottom: 0.65rem;
    > div {
      width: 0.56rem;
      height: 0.05rem;
      background: #1941b1;
      border-radius: 0.02rem;
      margin: 0.26rem 0 0.36rem;
    }
    > p {
      font-size: 0.3rem;
      line-height: 0.3rem;
      font-family: OPPOSans;
      font-weight: 800;
      color: #1941b1;
    }
    > span:last-child {
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-family: OPPOSans;
      font-weight: 400;
      color: #595757;
      margin: 0.23rem 0 0;
    }
  }
  .content_text {
    font-size: 0.24rem;
    font-family: OPPOSans;
    font-weight: 400;
    color: #595757;
    text-align: justify;
  }
  .number_box {
    width: 12.44rem;
    margin: 0 auto;
    margin-top: 0.65rem;
    img {
      height: 5.36rem;
      margin: 0.9rem 0 0.8rem;
    }
  }
  .settlement_box {
    width: 12.44rem;
    margin: 0.65rem auto 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    .img1 {
      height: 4.01rem;
      margin: 0rem auto 0.8rem;
    }
    .img2 {
      height: 4.6rem;
      margin: 0.55rem auto 0.8rem;
    }
    .img3 {
      height: 5.46rem;
      margin: 0.55rem auto 0.8rem;
    }
    .img4 {
      height: 4.92rem;
      margin: 0.55rem auto 0.8rem;
    }
  }
  .oneCard_box {
    width: 12.44rem;
    margin: 0.65rem auto 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      height: 6.98rem;
    }
  }
  .medical_box {
    width: 12.44rem;
    margin: 0.65rem auto 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    .img1 {
      height: 4.01rem;
      margin: 0rem auto 0.8rem;
    }
    .img2 {
      height: 8.12rem;
      margin: 0.55rem auto 0.8rem;
    }
    .img3 {
      height: 5.1rem;
      margin: 0.55rem auto 0.8rem;
    }
  }
}
</style>