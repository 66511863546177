<template>
  <div>
    <div class="float-bar-nav" @click="clickTop" v-show="navShow">
      <img src="../assets/images/top.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      top: 0,
      navShow: false,
    };
  },
  watch: {
    // 监听top值的变化
    top(newValue) {
      if (newValue > document.documentElement.clientHeight / 2) {
        this.navShow = true;
      } else {
        this.navShow = false;
      }
    },
  },
  methods: {
    clickTop() {
      var x = setInterval(function () {
        if (document.documentElement.scrollTop != 0) {
          document.documentElement.scrollTop -= 100;
        } else {
          clearInterval(x);
        }
      }, 2);
    },
  },
  mounted() {
    let style1 = function () {
      return "color: #1941b1; font-size: 28px; text-shadow: 0 0 10px rgba(255, 255, 255, 1)";
    };
    console.log("%c Welcome to Jingchenkeji!", style1(),);
    window.addEventListener("scroll", () => {
      this.top =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
    });
  },
};
</script>
<style scoped lang="less">
.float-bar-nav {
  position: fixed;
  bottom: 10vh;
  right: 0.3rem;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 0.5rem;
  background: #eee;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0.06rem rgba(0, 0, 0, 0.12);
  cursor: pointer;
  img {
    width: 0.2rem;
    height: 0.2rem;
  }
}
</style>