<template>
  <div class="main_index">
    <el-carousel class="top_bg" indicator-position="none" height="7.08rem">
      <el-carousel-item v-for="item in 4" :key="item">
        <div class="banner">
          <img src="../assets/images/top-bg.png" alt="" />
          <div>
            <span
              >助力建设
              <div>智慧医院建设&评级</div>
            </span>
            <div class="line"></div>
            <div>
              <div>互联网+智慧医疗服务商</div>
              <span
                >探 索 与 推 动 医 疗 信 息 化 · 数 字 化 和 智 慧 化 进 程 · 改
                善 就 医 流 程 与 体 验</span
              >
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="jc_title_box">
      <div class="jc_title">
        探索与推动医疗数字化信息化<br />改善就医流程与体验
      </div>
      <div class="jc_toast">
        致力于探索与推动医疗信息化、数字化和智慧化进程，改善就医流程与体验，打造中国互联网医疗领域领先企业。
      </div>
    </div>
    <div class="jc_card">
      <div class="jc_card_box" style="margin-right: 0.66rem">
        <div class="jc_title">智慧建设评级</div>
        <div class="jc_card_banner">
          <img src="../assets/images/index-card1.png" alt="" />
        </div>
        <div class="jc_card_bottom">
          <div class="jc_toast jc_card_content">
            <span
              >通过一站式智慧一样解决方案，为医院全方位建设信
              息化就医流程，打造多场景线上服务模式，全方位提
              高患者就诊体验。</span
            >
          </div>
          <div class="jc_card_active">更多介绍　>></div>
        </div>
      </div>
      <div class="jc_card_box">
        <div class="jc_title">智慧管理评级</div>
        <div class="jc_card_banner">
          <img src="../assets/images/index-card2.png" alt="" />
        </div>
        <div class="jc_card_bottom">
          <div class="jc_toast jc_card_content">
            <span
              >通过解构医院管理模式，创新医务管理模式，实现医院
              管理的信息化、统一化、可视化，加强各类风险事件的
              可控度，为全院平稳运营提供安全保障。</span
            >
          </div>
          <div class="jc_card_active">更多介绍　>></div>
        </div>
      </div>
    </div>
    <div class="jc_chart">
      <div class="jc_label_title">
        <div>产品构成</div>
        <div>Product Architecture</div>
        <div></div>
      </div>
      <div class="jc_chart_content">
        <img src="../assets/images/cpgc.png" alt="" />
      </div>
    </div>
    <div class="jc_trends">
      <div class="jc_label_title">
        <div>最新动态</div>
        <div>Product Architecture</div>
        <div></div>
      </div>
      <div class="jc_trends_box">
        <div class="jc_trends_card">
          <div class="jc_trends_card_banner">
            <img src="../assets/images/trends1.png" alt="" />
          </div>
          <div class="jc_card_title">
            <div>25<br />JAN</div>
            <div>
              重构传统服务模式 打造多维度智慧服务
              实现高质量发展丨张家港市第一人民医院
            </div>
          </div>
          <div class="jc_card_content">
            随着新医学模式的建立和医疗保险制度的全面展开，社会对医院的服务态度、服务程度、服务方式、服务效率、服务质量都提出了更高的要求，因此，医院要主动适应这些变化，一方面创新传统就医方式，改变让患者线下等待就诊的模式，积极提供多渠道的患者服务；另一方面，树立以患者为中心的思想，适时不断优化医疗服务的结构和布局，向多维度发展，为病人创造良好的诊疗康复环境，适应并满足人们日益增长的预防医疗保健等多层次需求。
          </div>
          <div class="jc_card_bottom"><a target="_blank" href="https://mp.weixin.qq.com/s?__biz=MzIyMDE0NjAzMw==&mid=2247487371&idx=1&sn=1f7a0d380c7f4ee94e4b7c1a959b6df5&chksm=97d13f95a0a6b68370b7251cd098daee7d9655a963f72016f4ce91eadca1ec09534ff1ddf517&token=150968684&lang=zh_CN#rd">更多介绍　></a> ></div>
        </div>
        <div class="jc_trends_card">
          <div class="jc_trends_card_banner">
            <img src="../assets/images/trends2.png" alt="" />
          </div>
          <div class="jc_card_title">
            <div>2<br />JAN</div>
            <div>
              国务院办公厅转发丨发展“互联网+医疗健康”，进一步完善互联网诊疗收费政策
            </div>
          </div>
          <div class="jc_card_content">
            自2018年起，国家开始推广医保电子凭证，探索实现医保卡“互联互通”。2019年4月，国务院印发《促进“互联网+”行动计划纲要》，其中明确提出要“推动医保卡与移动支付、第三方支付工具融合，开展实用可行的异地就医费用直接结算”，为医保电子凭证应用提供了有力支持。《国务院关于推进医疗健康大数据发展行动计划（2019-2022年）》也提出，要促进医保与电子健康档案相结合，实现电子凭证互认互用，为群众提供更加便利、高效和优质的医疗服务。
          </div>
          <div class="jc_card_bottom"><a target="_blank" href="https://mp.weixin.qq.com/s?__biz=MzIyMDE0NjAzMw==&mid=2247487289&idx=1&sn=aa6be42870eaece84f3a291f09db15c1&chksm=97d13f27a0a6b631896ad9f17fee1659364786b76791868590a7d43f7a450e1901e5b983f308&token=150968684&lang=zh_CN#rd">更多介绍　>></a> </div>
        </div>
        <div class="jc_trends_card">
          <div class="jc_trends_card_banner">
            <img src="../assets/images/trends3.png" alt="" />
          </div>
          <div class="jc_card_title">
            <div>10<br />JAN</div>
            <div>精宸科技|点击领取 医院智慧服务评级攻略</div>
          </div>
          <div class="jc_card_content">
            随着互联网等信息技术在医疗领域的广泛应用，“互联网+医疗服务”新业态快速发展，
            一方面惠及患者，人民群众的就医感受得到不断提升；一方面作为新事物，参与主体多、涉及领域广，隐私安全风险高，固为规范互联网诊疗行为，2019年初，国家卫生健康委正式发布了《医院智慧服务分级评估标准体系（试行）》以下是我们对于政策的一些解读以及明确精宸科技所能覆盖评级要求的具体评分项目。
          </div>
          <div class="jc_card_bottom"><a target="_blank" href="https://mp.weixin.qq.com/s?__biz=MzIyMDE0NjAzMw==&mid=2247486557&idx=1&sn=b84eded08f3b09a774e1045476160883&chksm=97d13c43a0a6b555aacaafd64efff2d652c108eb0e2cda299cd633fc6a784bb172767b204c22&token=150968684&lang=zh_CN#rd"> 更多介绍　>></a></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Index",
};
</script>
<style scoped lang="less">
.main_index {
  .top_bg {
    width: 100%;
    height: 7.08rem;
    .banner {
      width: 100%;
      height: 100%;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      > div {
        position: absolute;
        top: 1.52rem;
        left: 3.5rem;
        > span:first-child {
          font-size: 0.68rem;
          font-family: Alimama ShuHeiTi;
          font-weight: bold;
          color: #ffffff;
        }
        .line {
          width: 0.48rem;
          height: 0.08rem;
          background: #ffffff;
          margin: 0.4rem 0 0.35rem;
        }
        > div:last-child {
          font-size: 0.37rem;
          font-family: OPPOSans;
          font-weight: 500;
          color: #ffffff;
          span {
            font-size: 0.16rem;
            font-family: OPPOSans;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
    }
  }
  .jc_title {
    font-size: 0.36rem;
    font-family: OPPOSans;
    font-weight: bold;
    color: #1941b1;
    text-align: center;
  }
  .jc_toast {
    font-size: 0.2rem;
    font-family: OPPOSans;
    font-weight: 400;
    color: #3e3a39;
  }
  .jc_title_box {
    text-align: center;
  }
  .jc_title_box {
    .jc_title {
      width: 100%;
      margin: 0.93rem 0 0.32rem;
    }
  }
  .jc_card {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    .jc_card_box {
      width: 5.4rem;
      padding-bottom: 0.48rem;
      cursor: default;
      .jc_card_banner {
        width: 100%;
        margin: 0.3rem 0;
        overflow: hidden;
        height: 3rem;
        img {
          width: 100%;
          height: 3rem;
          -webkit-transition: 0.3s linear;
          transition: 0.3s linear;
        }
        img:hover {
          transform: scale(1.1);
        }
      }
      .jc_card_content {
        border-bottom: 0.01rem solid #c1c1c1;
        padding-bottom: 0.27rem;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
      .jc_card_bottom {
        padding: 0 0.4rem;
        border-left: 0.1rem solid #3054b9;
        .jc_card_active {
          font-size: 0.18rem;
          font-family: OPPOSans;
          font-weight: 400;
          color: #1941b1;
          text-align: right;
          margin-top: 0.27rem;
        }
      }
    }
  }
  .jc_chart {
    height: 7.78rem;
    background: #1842b3;
    width: 100%;
    margin-top: 0.75rem;
    .jc_label_title {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;

      > div:first-child {
        font-size: 0.36rem;
        font-family: OPPOSans;
        font-weight: bold;
        margin-top: 0.65rem;
      }
      > div:nth-child(2) {
        font-size: 0.14rem;
        font-family: OPPOSans;
        font-weight: 400;
        margin: 0.12rem 0 0.25rem;
      }
      > div:last-child {
        width: 0.48rem;
        height: 0.05rem;
        background: #fff;
      }
    }
    .jc_chart_content {
      width: 70%;
      height: 5.71rem;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
        margin: auto;
        object-fit: contain;
      }
    }
  }
  .jc_trends {
    background: #f7f8f8;
    padding: 70px 0;
    .jc_trends_box {
      display: flex;
      justify-content: center;
      margin-top: 0.37rem;
      cursor: default;
    }
    .jc_label_title {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #1941b1;

      > div:first-child {
        font-size: 0.36rem;
        font-family: OPPOSans;
        font-weight: bold;
        margin-top: 0.65rem;
      }
      > div:nth-child(2) {
        font-size: 0.14rem;
        font-family: OPPOSans;
        font-weight: 400;
        margin: 0.12rem 0 0.25rem;
      }
      > div:last-child {
        width: 0.48rem;
        height: 0.05rem;
        background: #1941b1;
      }
    }
    .jc_trends_card {
      width: 3.36rem;
      height: 5.09rem;
      background: #fff;
      margin: 0 0.36rem;
      cursor: default;
      overflow: hidden;
      .jc_trends_card_banner {
        border-bottom: 0.05rem solid #1941b1;
        width: 3.36rem;
        height: 2rem;
        overflow: hidden;
        img:hover {
          transform: scale(1.1);
        }
        img {
          width: 100%;
          height: 100%;
          -webkit-transition: 0.3s linear;
          transition: 0.3s linear;
          object-fit: contain;
        }
      }
      .jc_card_title {
        display: flex;
        margin-top: 0.24rem;
        > div:first-child {
          min-width: 0.48rem;
          padding: 0.16rem 0;
          background: #1941b1;
          text-align: center;
          font-size: 0.15rem;
          font-family: NotoSansCJKkr;
          font-weight: 900;
          color: #ffffff;
        }
        > div {
          font-size: 0.15rem;
          font-family: NotoSansCJKkr;
          font-weight: bold;
          color: #222222;
          padding: 0.15rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .jc_card_content {
        font-size: 0.12rem;
        font-family: Gulim;
        font-weight: 400;
        color: #666666;
        margin: 0.3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-align: justify;
      }
      .jc_card_bottom {
        font-size: 0.17rem;
        font-family: OPPOSans;
        font-weight: 500;
        color: #1941b1;
        text-align: right;
        margin-top: 0.5rem;
        padding: 0 0.3rem;
        a{
          color: #1941b1;
          text-decoration: none;
        }
      }
    }
  }
}
</style>