<template>
  <div id="app">
    <myHeader />
    <router-view />
    <floatNav />
    <myBottom />
  </div>
</template>

<script>
import myHeader from "@/components/header.vue";
import myBottom from "@/components/bottom.vue";
import floatNav from "@/components/floatNav.vue";
export default {
  name: "App",
  components: {
    myHeader,
    myBottom,
    floatNav,
  },
};
</script>

<style>
html,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
input,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
textarea,
article,
aside,
audio,
canvas,
figure,
footer,
header,
mark,
menu,
nav,
section,
time,
video {
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  word-break: break-all;
  box-sizing: border-box;
}
</style>
