import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index'
import About from '../views/About'
import Programme from '../views/Programme'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index,
        meta: {
            title: "精宸科技"
        },
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            title: "关于精宸"
        },
    },
    {
        path: '/programme',
        name: 'Programme',
        component: Programme,
        meta: {
            title: "解决方案"
        },
    },
]

const router = new VueRouter({
    routes,
    mode: 'hash',
})
router.beforeEach((to, from, next) => {//beforeEach是router的钩子函数，在进入路由前执行
    if (to.meta.title) {//判断是否有标题
        document.title = to.meta.title
    }
    next()//执行进入路由，如果不写就不会进入目标页
})
export default router
