<template>
  <div class="about_box">
    <div class="banner">
      <img src="../assets/images/introduction-banner.png" alt="" />
    </div>
    <div class="about_text">
      <div class="about_text_title">关于精宸科技</div>
      <div class="about_text_value">
        <p>
          上海精宸科技集团股份有限公司（精宸科技）创立于2013年，位于上海工程技术大学国家大学科技园内。公司致力于探索与推动医疗健康领域信息化、数字化进程，改善就医流程与体验、提升医疗质量，保障患者安全。
        </p>

        <p>
          作为上海市高新技术企业、专精特新企业，精宸科技已获得行业、相关软件著作证书110余项先后通过CMMI-3、ISO9001、ISO27001等质量管理体系认证。
        </p>
        <p>
          成立至今，精宸科技以智慧服务、智慧管理建设为特色的智慧医疗整体解决方案，已持续为600余家医疗机构及多个区域平台与医共体提供服务，全面助力医疗机构智慧医院评级，推动医疗数字化转型。
        </p>
      </div>
      <!-- <div class="about_number">
        <div class="about_number_li">
          <img src="../assets/images/about/city.png" alt="" />
          <div>城市覆盖</div>
        </div>
        <div class="about_number_li">
          <img src="../assets/images/about/mzbook.png" alt="" />
          <div>医院客户案例</div>
        </div>
        <div class="about_number_li">
          <img src="../assets/images/about/yccs.png" alt="" />
          <div>全国分支机构</div>
        </div>
        <div class="about_number_li">
          <img src="../assets/images/about/money.png" alt="" />
          <div>交易笔数</div>
        </div>
      </div> -->
      <div class="about_img">
        <div class="about_img_li">
          <img src="../assets/images/about/about1.png" alt="" />
        </div>
        <div class="about_img_li">
          <img src="../assets/images/about/about2.png" alt="" />
        </div>
        <div class="about_img_li">
          <img src="../assets/images/about/about3.png" alt="" />
        </div>
      </div>
    </div>
    <div class="about_label">企业文化</div>
    <div class="culture_box">
      <div class="culture_box_li">
        <img src="../assets/images/about/culture1.png" alt="" />
        <div>使命</div>
        <div>致力于人类健康事业的创新</div>
      </div>
      <div class="culture_box_li">
        <img src="../assets/images/about/culture2.png" alt="" />
        <div>愿景</div>
        <div>探索与推动医疗信息化、数字化和智慧化进程，改善就医流程与体验</div>
      </div>
      <div class="culture_box_li">
        <img src="../assets/images/about/culture3.png" alt="" />
        <div>核心价值观</div>
        <div>追求高绩效、自由与责任、团队与协作</div>
      </div>
    </div>
    <div class="about_label">发展历程</div>
    <div class="course_box">
      <div class="course_swiper">
        <el-carousel
          indicator-position="none"
          arrow="never"
          @change="onSwiper"
          :setActiveItem="swiperIndex"
          ref="carousel"
        >
          <el-carousel-item v-for="(item, index) in yearArr" :key="index">
            <div class="course_swiper_li">
              <div class="course_swiper_li_text">
                <span>{{ item.year }}</span>
                <div v-html="item.text"></div>
              </div>
              <img :src="item.img" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="btn_left" @click="onPrev">
          <div></div>
        </div>
        <div class="btn_right" @click="onNext">
          <div></div>
        </div>
      </div>
      <div class="line">
        <div class="line_li" v-for="(item, index) in yearArr" :key="index">
          <div></div>
          <div @click="selectIndex(index)">
            <span v-show="swiperIndex == index">{{ item.year }}</span>
            <div></div>
            <div v-show="swiperIndex != index" class="value">
              {{ item.year }}
            </div>
            <div v-show="swiperIndex == index"></div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="about_label" id="honor">荣誉证书</div>
    <div class="honor_box">
      <img src="../assets/images/about/honor.png" alt="" />
    </div>
    <div class="softness_box">
      <el-carousel indicator-position="none" arrow="hover" loop>
        <el-carousel-item v-for="(item, index) in softnessArr" :key="index">
          <img
            :src="t"
            :style="i == 0 ? 'margin-left:0.11rem;' : ''"
            alt=""
            v-for="(t, i) in item"
            :key="i"
          />
        </el-carousel-item>
                <el-carousel-item v-for="(item, index) in softnessArr" :key="2+index">
          <img
            :src="t"
            :style="i == 0 ? 'margin-left:0.11rem;' : ''"
            alt=""
            v-for="(t, i) in item"
            :key="i"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
export default {
  name: "About",
  data() {
    return {
      swiperIndex: 0,
      yearArr: [
        {
          year: "2013",
          img: require("../assets/images/about/2013.jpg"),
          text: "精宸科技成立，公司愿景：致力于人类健康事业的创新。",
        },
        {
          year: "2014",
          img: require("../assets/images/about/2014.jpg"),
          text: "精宸科技联合第二军医大学研发开发的军事卫勤保障信息化产品及解决方案在全国数十家部队医院深入用。",
        },
        {
          year: "2015",
          img: require("../assets/images/about/2015.jpg"),
          text: "1、精宸科技联合解放军第一一三医院研发上线移动支付1.0系统。<br/>2、精宸科技联合解放军第一〇一医院研发上线互联网统一接入平台",
        },
        {
          year: "2016",
          img: require("../assets/images/about/2016.jpg"),
          text: "精宸科技成功通过ISO9001质量体系认证。",
        },
        {
          year: "2017",
          img: require("../assets/images/about/2017.jpg"),
          text: "精宸科技成为支付宝和微信支付认证合作伙伴，首创《智慧医院+》解决方案，以移动支付2.0系统为主线，切实解决“看病三长一短”。",
        },
        {
          year: "2018",
          img: require("../assets/images/about/2018.jpg"),
          text: "精宸科技推出全国首个大型综合公立三甲医院便民服务微应用，微信小程序实名制能力一键生成电子就诊卡，一部手机完成就医全流程。",
        },
        {
          year: "2019",
          img: require("../assets/images/about/2019.jpg"),
          text: "1、精宸科技与苏州广济医院合作，推出慢病随访管理平台，为患者提供健康管理的长期追踪服务。<br/>2、精宸科技在广东省政策支持下，为当地建设了首批互联网医院，实现线上问诊、处方开具、药品配送等服务。",
        },
        {
          year: "2020",
          img: require("../assets/images/about/2020.jpg"),
          text: "受疫情影响，医院门诊业务停摆，精宸科技免费为百余家医院提供线上问诊及预检分诊平台。",
        },
        {
          year: "2021",
          img: require("../assets/images/about/2021.jpg"),
          text: "在CHIMA大会医院信息化论坛上，精宸科技发表了以“新形势下的智慧医务管理探索”为主题的专题演讲，并带来了全新的智慧医务管理平台解决方案。",
        },
        {
          year: "2022",
          img: require("../assets/images/about/2022.jpg"),
          text: "1、历“九”弥新 砥砺奋进，喜迎精宸成立九周年<br/>2、响应国家号召，以文件5大类18项要求为核心，打造智慧医务管理平台，助力公立医院实现高质量发展。",
        },
      ],
      softnessArr: [
        [
          require("../assets/images/softness/2.jpg"),
          require("../assets/images/softness/3.jpg"),
          require("../assets/images/softness/4.jpg"),
          require("../assets/images/softness/5.jpg"),
        ],
        [
          require("../assets/images/softness/6.jpg"),
          require("../assets/images/softness/7.jpg"),
          require("../assets/images/softness/8.jpg"),
          require("../assets/images/softness/9.jpg"),
        ],
      ],
    };
  },
  methods: {
    onSwiper(e) {
      this.swiperIndex = e;
    },
    onPrev() {
      this.swiperIndex--;
      this.$refs.carousel.setActiveItem(this.swiperIndex);
    },
    onNext() {
      this.swiperIndex++;
      this.$refs.carousel.setActiveItem(this.swiperIndex);
    },
    selectIndex(index) {
      this.swiperIndex = index;
      this.$refs.carousel.setActiveItem(index);
    },
  },
};
</script>
<style scoped lang="less">
.about_box {
  background: #f5f5f5;
  padding-bottom: 1.55rem;
  .banner {
    width: 100%;
    height: 4.92rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .about_text {
    padding: 1.2rem 3.35rem;
    > .about_text_value {
      font-size: 0.22rem;
      font-family: OPPOSans;
      font-weight: 400;
      color: #3e3a39;
      line-height: 0.43rem;
      text-indent: 0.44rem;
    }
    .about_text_title {
      font-size: 0.35rem;
      font-family: OPPOSans;
      font-weight: 500;
      color: #231815;
      margin-bottom: 0.6rem;
    }
    .about_number {
      display: flex;
      align-items: center;
      margin-top: 0.96rem;
      .about_number_li {
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 0.48rem;
        font-family: OPPOSans;
        font-weight: bold;
        color: #231815;
        img {
          width: 0.89rem;
          height: 1.07rem;
          margin-bottom: 0.2rem;
        }
        > div:last-child {
          font-size: 0.24rem;
          font-family: OPPOSans;
          font-weight: 500;
          color: #231815;
        }
      }
    }
    .about_img {
      display: flex;
      margin: 1.25rem 0 0rem;
      .about_img_li {
        width: 4.08rem;
        height: 3.06rem;
        overflow: hidden;
        margin-left: 0.1rem;
        img:hover {
          transform: scale(1.1);
        }
        img {
          width: 100%;
          height: 100%;
          -webkit-transition: 0.3s linear;
          transition: 0.3s linear;
        }
      }
    }
  }
  .about_label {
    text-align: center;
    font-size: 0.35rem;
    font-family: OPPOSans;
    font-weight: 500;
    color: #231815;
  }
  .culture_box {
    margin: 0.63rem 0 1.53rem;
    width: 100%;
    height: 6rem;
    background-image: url("../assets/images/about/culture-bg.png");
    display: flex;
    justify-content: center;
    align-items: center;
    .culture_box_li {
      width: 4.02rem;
      height: 4.79rem;
      background: #eaf3f9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 0.18rem;
      position: relative;
      overflow: hidden;
      
      div {
        font-size: 0.3rem;
        font-family: OPPOSans;
        font-weight: 400;
        color: #858585;
        margin-top: 0.25rem;
      }
      >div:last-child{
        font-size: 0.2rem;
        padding: 0 0.2rem;
        position: absolute;
        bottom: -15%;
        transition: all 1s;
      }
    }
    .culture_box_li:hover{
      >div:last-child{
        bottom:20%;
       
      }
    }
  }
  .honor_box {
    width: 12.44rem;
    height: 3.79rem;
    margin: 0.6rem auto 0;
    display: flex;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .course_box {
    width: 12.44rem;
    margin: 0.8rem auto 0;
    .course_swiper {
      width: 100%;
      height: 2.6rem;
      position: relative;
      /deep/ .el-carousel,
      /deep/ .el-carousel__container {
        height: 100%;
      }
      .course_swiper_li {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 0 1.52rem;
        justify-content: space-between;
        align-items: center;
        .course_swiper_li_text {
          font-size: 0.72rem;
          font-family: MiSans;
          font-weight: 400;
          color: #231815;
          div {
            padding-right: 0.3rem;
            margin-top: 0.3rem;
            font-size: 0.16rem;
            font-family: OPPOSans;
            font-weight: 400;
            color: #858585;
          }
        }
        img {
          width: 4.23rem;
          height: 2.51rem;
        }
      }
      .btn_left {
        width: 0.6rem;
        height: 0.58rem;
        background: #d4d4d4;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99;
        div {
          width: 0;
          height: 0;
          border-right: 9px solid #2c2c2c;
          border-top: 9px solid transparent;
          border-bottom: 9px solid transparent;
        }
      }
      .btn_right {
        width: 0.6rem;
        height: 0.58rem;
        background: #d4d4d4;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99;

        div {
          width: 0;
          height: 0;
          border-left: 9px solid #2c2c2c;
          border-top: 9px solid transparent;
          border-bottom: 9px solid transparent;
        }
      }
    }
    .line {
      display: flex;
      margin: 1rem 0 2.49rem;
      justify-content: center;
      cursor: pointer;
      .line_li {
        display: flex;
        align-items: center;
        > div:not(:nth-child(2)) {
          border-top: 0.02rem dashed #d4d4d4;
          height: 0;
          width: 0.51rem;
        }
        > div:nth-child(2) {
          position: relative;
          > div:nth-child(2) {
            width: 0.1rem;
            height: 0.1rem;
            background: #999999;
            border-radius: 50%;
          }
          span {
            position: absolute;
            font-size: 0.35rem;
            font-family: MiSans;
            font-weight: 400;
            color: #231815;
            top: -0.6rem;
            left: -0.45rem;
            z-index: 99;
            width: 1rem;
            text-align: center;
          }
          > div:last-child {
            position: absolute;
            width: 0;
            height: 0;
            border-right: 0.09rem solid #2c2c2c;
            border-top: 0.09rem solid transparent;
            border-bottom: 0.09rem solid transparent;
            transform: rotate(90deg);
            top: 0.2rem;
          }
          .value {
            position: absolute;
            top: 0.15rem;
            font-size: 0.15rem;
            font-family: MiSans;
            font-weight: 400;
            color: #858585;
            white-space: nowrap;
            left: -0.12rem;
          }
        }
      }
    }
  }
  .softness_box {
    background: #fff;
    width: 12.44rem;
    margin: 0rem auto;
    padding: 0.4rem 0;
    /deep/ .el-carousel,
    /deep/ .el-carousel__container {
       height: 4.01rem;
    }
    img {
      width: 2.97rem;
      height: 4.01rem;
      margin-right: 0.1rem;
    }
  }
}
</style>