<template>
  <div class="main-header-box">
    <div :class="!navShow ? 'header_box' : 'header_box visible'">
      <img src="../assets/images/logo.png" alt="" />
      <div class="menu">
        <el-tabs
          :value="activeName"
          @tab-click="handleClick"
          style="font-size: 0.2rem"
        >
          <el-tab-pane label="首页" name="1"></el-tab-pane>
          <el-tab-pane label="关于精宸" name="2"></el-tab-pane>
          <el-tab-pane label="解决方案" name="3"></el-tab-pane>
          <!-- <el-tab-pane label="应用案例" name="4"></el-tab-pane>
          <el-tab-pane label="咨讯中心" name="5"></el-tab-pane> -->
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "",
      top: "",
      navShow: true,
    };
  },
  watch: {
    $route(to, from) {
      this.routeIndex(to);
    },
    // 监听top值的变化
    top(newValue, oldValue) {
      // 等新值大于90的时候再做变化
      if (newValue > 90) {
        if (newValue > oldValue) {
          this.navShow = false;
        } else {
          this.navShow = true;
        }
      }
    },
  },
  methods: {
    handleClick(tab, event) {
      switch (tab.name) {
        case "1":
          if (this.activeName != 1) {
            this.$router.push({
              name: "Index",
            });
            document.documentElement.scrollTop = 0;
          }
          this.activeName = tab.name;
          break;
        case "2":
          if (this.activeName != 2) {
            this.$router.push({
              name: "About",
            });
            document.documentElement.scrollTop = 0;
          }
          this.activeName = tab.name;
          break;
        case "3":
          if (this.activeName != 3) {
            this.$router.push({
              name: "Programme",
            });
           document.documentElement.scrollTop = 0
          }
          this.activeName = tab.name;
          break;
        default:
          break;
      }
    },
    routeIndex(to) {
      if (to.name == "Index") {
        this.activeName = "1";
      }
      if (to.name == "About") {
        this.activeName = "2";
      }
      if (to.name == "Programme") {
        this.activeName = "3";
      }
    },
  },
  mounted() {
    this.routeIndex(this.$route);
    window.addEventListener("scroll", () => {
      if (document.documentElement.scrollTop == 0) {
        this.navShow = true;
      }
      this.top =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
    });
  },
};
</script>
<style scoped lang="less">
.main-header-box {
  height: 0.89rem;

  .header_box {
    box-shadow: 0 0.01rem 0.03rem hsla(0, 0%, 7%, 0.1);
    width: 100%;
    height: 0.89rem;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: transform 0.2s;
    z-index: 99999;
    transform: translate3d(0, -100%, 0);
    > img {
      width: 1.62rem;
      height: 0.24rem;
    }
  }
  .visible {
    transform: translateZ(0);
  }
  .menu {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 1.55rem;
    /deep/ .el-tabs__header {
      margin: 0;
    }
    /deep/ .el-tabs__nav-wrap::after {
      display: none;
    }
    /deep/ .el-tabs__item {
      font-weight: bold;
      padding: 0 0.2rem;
      height: 0.4rem;
      box-sizing: border-box;
      line-height: 0.4rem;
      font-size: 0.2rem;
      color: #363636;
    }
    /deep/ .el-tabs__item:hover {
      color: #1941b1;
    }
    /deep/ .el-tabs__item.is-active {
      color: #1941b1;
    }
    /deep/ .el-tabs__active-bar {
      background-color: #1941b1;
      height: 0.02rem;
    }
  }
}
</style>